<template>
    <div class="page-view">
        <app-section id="body" v-html="content" />
    </div>
</template>

<script>
	import AppSection from "@/components/AppSection.vue";

	export default {
		components: {
			AppSection
		},
		data: () => {
			return {
				content: ''
			};
		},
		async mounted() {
			this.content = (await this.axios.get(`/pages.php?slug=${this.$route.params.page}`)).data.content;
		}
	};
</script>

<style lang="scss" scoped>
	.page-view {
		background-color: white;
		padding: 130px 0 130px 0;
	}

	@media only screen and (max-width: 1200px) {
		.page-view {
			& #body {
				padding: 0 20px 0 20px;
			}
		}
	}
</style>